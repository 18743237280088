import { Controller } from '@hotwired/stimulus'
import { pausePlayers } from './utils/pausePlayers'

/*
 * Description
 * -------
 *
 * Handles Streamable player.
 * Must be used on iframe element.
 * Can be used multiple times in the document.
 *
 * Values
 * -------
 *
 * - watchTimesPath - (required) - API endpoint to save watch time
 *
 * Usage
 * -------
 *
 * <iframe
 *   data-controller="streamable-player"
 *   data-streamable-player-watch-times-path-value="http://example.com"
 * >
 * </iframe>
 */

export default class extends Controller {
  static values = { watchTimesPath: String }

  handleWindowBlur() {
    if (document.activeElement == this.element) {
      fetch(this.watchTimesPathValue, {
        method: 'POST',
      })
      pausePlayers()
    }
  }
}

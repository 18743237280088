import { Controller } from '@hotwired/stimulus'

/*
 * Description
 * -------
 *
 * Toggles CSS class on specified element or controller element.
 * Can be used on any element.
 * Can be used multiple times in the document.
 *
 * Targets
 * -------
 *
 * element - (required) - element to toggle class on
 *
 * Values
 * -------
 *
 * class - (required) - CSS class to toggle
 *
 * Usage
 * -------
 *
 * <div
 *   data-controller="toggle"
 *   data-toggle-class-value="hidden"
 * >
 *   <div data-action="click->toggle#toggle"></div>
 *   <div data-toggle-target="element"></div>
 * </div>
 */

export default class extends Controller {
  static targets = ['element']

  static values = { class: String }

  toggle() {
    if (this.hasElementTarget) {
      Array.from(this.elementTargets).forEach((elementTarget) => {
        elementTarget.classList.toggle(this.classValue)
      })
    } else {
      this.element.classList.toggle(this.classValue)
    }
  }
}

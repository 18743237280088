import Rails from '@rails/ujs'
import { addScrollLock, removeScrollLock } from '../../controllers/supporters-app/utils/scrollLock'

Rails.confirm = (message, element) => {
  const { content, confirmLabel, cancelLabel } = element.dataset

  const modalHTML = `
    <div class="modal">
      <div class="modal__overlay"></div>
      <div class="modal__dialog">
        <p class="modal__title">${message || 'Are you sure?'}</p>
        <div class="modal__content">${content || ''}</div>
        <div class="modal__buttons">
          <button data-behavior="cancel" class="text-button text-button--underline">${
            cancelLabel || 'Cancel'
          }</button>
          <button data-behavior="confirm" class="button button--primary">${
            confirmLabel || 'Confirm'
          }</button>
        </div>
      </div>
    </div>
  `

  document.body.insertAdjacentHTML('beforeend', modalHTML)
  addScrollLock()

  const modal = document.body.lastElementChild

  const removeModal = () => {
    modal.remove()
    removeScrollLock()
  }

  modal.querySelector('.modal__overlay').addEventListener(
    'click',
    (event) => {
      event.preventDefault()
      removeModal()
    },
    { once: true },
  )

  modal.querySelector("[data-behavior='cancel']").addEventListener(
    'click',
    (event) => {
      event.preventDefault()
      removeModal()
    },
    { once: true },
  )

  modal.querySelector("[data-behavior='confirm']").addEventListener(
    'click',
    (event) => {
      event.preventDefault()

      removeModal()
      element.removeAttribute('data-confirm')
      element.click()
    },
    { once: true },
  )

  return false
}

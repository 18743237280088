import { Controller } from '@hotwired/stimulus'

/*
 * Description
 * -------
 *
 * Saves element scroll position and restores it on controller connect.
 * Can be used on any element.
 * Can be used multiple times in the document.
 *
 * Values
 * -------
 *
 * identifier - (required) - identifier to save scroll position (will be used as window property)
 *
 * Usage
 * -------
 *
 * <div
 *   data-controller="scroll-save"
 *   data-scroll-save-identifier-value="identifier"
 *   data-action="scroll->scroll-save#save"
 * >
 * </div>
 */

export default class extends Controller {
  static values = { identifier: String }

  connect() {
    if (window[this.identifierValue]) {
      this.element.scrollLeft = window[this.identifierValue]
    }
  }

  save() {
    window[this.identifierValue] = this.element.scrollLeft
  }
}

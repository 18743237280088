import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['confirmButton', 'inputContainer', 'input', 'selectContainer', 'select']

  static values = {
    monthly: Number,
    monthlyPretty: String,
    annual: Number,
    annualPretty: String,
  }

  type = 'monthly'

  setButtonContent(value) {
    this.confirmButtonTarget.innerHTML = `Checkout ($${value / 100})`
  }

  handleChangeType(e) {
    this.type = e.target.dataset['type']

    if (this.selectTarget.classList.contains('hidden')) {
      this.handleInputChange()
    } else {
      this.handleSelectChange()
    }
  }

  handleInputChange() {
    const value = parseInt(this.inputTarget.value)

    if (value && value > 0) {
      this.setButtonContent(value * this.typeValue)
    }
  }

  handleSelectChange() {
    const value = parseInt(this.selectTarget.value)

    if (value && value > 0) {
      if (value === 10) {
        // Hide select
        this.selectContainerTarget.classList.add('hidden')
        this.selectTarget.disabled = true

        // Show input
        this.inputContainerTarget.classList.remove('hidden')
        this.inputTarget.disabled = false

        this.inputTarget.value = 10
        this.inputTarget.focus()
      }

      this.setButtonContent(value * this.typeValue)
    }
  }

  get typeValue() {
    return this.type === 'monthly' ? this.monthlyValue : this.annualValue
  }
}

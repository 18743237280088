import { Controller } from '@hotwired/stimulus'
import { addScrollLock, removeScrollLock } from './utils/scrollLock'

/*
 * Description
 * -------
 *
 * Handles modal.
 * Can be used on any element.
 * Can be used multiple times in the document.
 *
 * Values
 * -------
 *
 * open - (optional) - whether the modal should be open on page load
 *
 * Usage
 * -------
 *
 * <div class="hidden" data-controller="modal">
 *   <div data-action="click->modal#open"></div>
 *   <div class="modal" data-modal-target="container">
 *     <div data-action="click->modal#close">Close</div>
 *   </div>
 * </div>
 */

export default class extends Controller {
  static targets = ['container']

  static values = { open: { type: Boolean, default: false } }

  connect() {
    if (this.openValue) {
      this.open()
    }
  }

  close() {
    this.containerTarget.classList.add('hidden')
    removeScrollLock()
  }

  open() {
    this.containerTarget.classList.remove('hidden')
    addScrollLock()
  }
}

import { Controller } from '@hotwired/stimulus'

/*
 * Description
 * -------
 *
 * Handles gallery with PhotoSwipe library.
 * Can be used on any element.
 * Can be used multiple times in the document.
 *
 * Values
 * -------
 *
 * id - (required) - gallery ID
 *
 * Usage
 * -------
 *
 * <div
 *   data-controller="gallery"
 *   data-gallery-id-value="24"
 *   data-action="popstate@window->gallery#handlePopstate"
 * >
 *   <a href="https://picsum.photos/800/600" data-pswp-width="800" data-pswp-height="600">
 *     <img src="https://picsum.photos/200/150" />
 *   </a>
 * </div>
 */

export default class extends Controller {
  static values = { id: String }

  async connect() {
    const { default: PhotoSwipeLightbox } = await import('photoswipe/lightbox')
    const { default: PhotoSwipe } = await import('photoswipe')

    this.lightbox = new PhotoSwipeLightbox({
      gallery: this.element,
      children: 'a',
      closeSVG:
        '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20"><path fill="currentColor" fill-rule="evenodd" d="m11.41 10 8.09-8.08L18.08.5 10 8.6 1.92.5.5 1.92 8.6 10 .5 18.08l1.42 1.42L10 11.4l8.08 8.09 1.42-1.42L11.4 10Z" clip-rule="evenodd"/></svg>',
      arrowNextSVG:
        '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 21 20"><path stroke="currentColor" stroke-width="2" d="M10.02 18.75 18.75 10m0 0-8.73-8.75M18.75 10H0"/></svg>',
      arrowPrevSVG:
        '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 21 20"><path stroke="currentColor" stroke-width="2" d="M10.98 1.25 2.25 10m0 0 8.73 8.75M2.25 10H21"/></svg>',
      zoom: false,
      counter: false,
      bgOpacity: 0.85,
      loop: false,
      history: true,
      preloaderDelay: 0,
      showHideAnimationType: 'none',
      preloadFirstSlide: false,
      pswpModule: PhotoSwipe,
    })

    this.lightbox.on('initialLayout', () => {
      document.dispatchEvent(new CustomEvent('wlaDisablePullToRefresh'))

      // Push new history state to close gallery on Back click
      if (!history.state?.gallery) {
        window.history.pushState({ gallery: this.idValue }, '')
      }
    })

    this.lightbox.on('close', () => {
      document.dispatchEvent(new CustomEvent('wlaEnablePullToRefresh'))

      // Simulate Back click if user closes gallery
      if (history.state?.gallery) {
        history.back()
      }
    })

    this.lightbox.init()
  }

  // Open/close gallery while user clicks Forward/Back browser button
  handlePopstate(e) {
    if (e.state?.gallery === this.idValue) {
      this.lightbox?.loadAndOpen(0)
    } else {
      // Simulate gallery close
      document.querySelector('.pswp__button.pswp__button--close')?.click()
    }
  }

  disconnect() {
    this.lightbox?.destroy()
  }
}

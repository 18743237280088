import { Controller } from '@hotwired/stimulus'

/*
 * Description
 * -------
 *
 * Handles PWA install prompt on Android devices.
 * Can be used on any element.
 * Should be used only once in the document.
 *
 * Targets
 * -------
 *
 * button - (required) - elements that triggers the install prompt
 *
 * Usage
 * -------
 *
 * <div class="hidden" data-controller="install-prompt-android" data-action="beforeinstallprompt@window->install-prompt-android#show">
 *   <div data-action="click->install-prompt-android#close">Close</div>
 *   <button data-install-prompt-android-target="button">Install</button>
 * </div>
 */

export default class extends Controller {
  static targets = ['button']

  show(e) {
    e.preventDefault()

    this.element.classList.remove('hidden')

    this.buttonTargets.forEach((target) => {
      target.addEventListener(
        'click',
        () => {
          e.prompt()
          this.element.classList.add('hidden')
        },
        { once: true },
      )
    })
  }

  close() {
    this.element.classList.add('hidden')
  }
}

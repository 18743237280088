import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input', 'button', 'flash']

  connect() {
    this.appliedButton = this.buttonTarget.innerHTML
    this.appliedCode = this.inputTarget.value
    this.appliedFlash = this.flashTarget.innerHTML
  }

  handleInputChange(e) {
    if (e.target.value === this.appliedCode) {
      this.buttonTarget.innerHTML = this.appliedButton
      this.flashTarget.innerHTML = this.appliedFlash
    } else {
      this.buttonTarget.innerHTML = 'Apply'
    }
  }

  handleButtonClick(e) {
    if (this.inputTarget.value === this.appliedCode) {
      e.preventDefault()
    }
  }
}

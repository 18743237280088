import { Controller } from '@hotwired/stimulus'

/*
 * Description
 * -------
 *
 * Handles WLA downloads page open.
 * Can be used on any element.
 * Can be used multiple times in the document.
 *
 * Usage
 * -------
 *
 * <button
 *   data-controller="downloads-link"
 *   data-action="click->downloads-link#handleClick"
 * >
 *  Downloads
 * </button>
 */

export default class extends Controller {
  handleClick() {
    if (window.webkit?.messageHandlers?.showDownloads?.postMessage) {
      window.webkit.messageHandlers.showDownloads.postMessage(true)
    }

    if (window.Android?.openDownloads) {
      window.Android.openDownloads()
    }
  }
}

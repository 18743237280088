import { Controller } from '@hotwired/stimulus'

/*
 * Description
 * -------
 *
 * Sets "--window-height" CSS variable on window resize.
 * Can be used on any element.
 * Should be used only once in the document.
 *
 * Usage
 * -------
 *
 * <body
 *   data-controller="window-height"
 *   data-action="resize@window->window-height#set"
 * ></body>
 */

export default class extends Controller {
  connect() {
    this.set()
  }

  set() {
    document.documentElement.style.setProperty(
      '--window-height',
      `calc(${window.innerHeight}px - env(safe-area-inset-bottom))`,
    )
  }
}

import { signInWithCustomToken } from '@firebase/auth'
import * as Sentry from '@sentry/browser'
import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getCustomToken, removeCustomToken } from '../../controllers/supporters-app/utils/auth'
import { isPWA } from '../../controllers/supporters-app/utils/dev'
import {
  registerServiceWorker,
  unregisterServiceWorker,
} from '../../controllers/supporters-app/utils/serviceWorker'

window.firebaseInitialize = (apiKey, authDomain, projectId, messagingSenderId, appId, tenantId) => {
  const config = {
    apiKey: apiKey,
    authDomain: authDomain,
    projectId: projectId,
    messagingSenderId: messagingSenderId,
    appId: appId,
  }

  initializeApp(config)
  getAuth().tenantId = tenantId

  getAuth().onAuthStateChanged((user) => {
    if (user) {
      registerServiceWorker()
    } else {
      if (!isPWA()) {
        unregisterServiceWorker()
      }
    }
  })
}

export const firebaseSignIn = () => {
  const customToken = getCustomToken()

  if (customToken) {
    signInWithCustomToken(getAuth(), customToken)
      .then(() => {
        removeCustomToken()
      })
      .catch((error) => {
        window.elasticApm.captureError(error)
        Sentry.captureException(error)
      })
  }
}

import { Controller } from '@hotwired/stimulus'
import { Turbo } from '@hotwired/turbo-rails'
import * as Sentry from '@sentry/browser'
import { getAuth } from 'firebase/auth'
import { toast } from './utils/toast'
import { csrfTokenHeaders } from "./utils/fetch";

/*
 * Description
 * -------
 *
 * Handles logout.
 * Can be used on any element.
 * Can be used multiple times in the document.
 *
 * Values
 * -------
 *
 * signOutPath - (required) - API endpoint to sign out
 * signInPath  - (required) - path to sign in page
 *
 * Usage
 * -------
 *
 * <button
 *   data-controller="logout"
 *   data-logout-sign-out-path-value="https://example.com"
 *   data-logout-sign-in-path-value="https://example.com"
 *   data-action="click->logout#logout"
 * >
 *  Logout
 * </button>
 */

export default class extends Controller {
  static values = { signOutPath: String, signInPath: String }

  async logout() {
    document.dispatchEvent(new CustomEvent('wlaDidLogout'))

    try {
      await getAuth().signOut()
      const response = await fetch(this.signOutPathValue, {
        method: 'DELETE',
        headers: csrfTokenHeaders(),
      })

      if (!response.ok) {
        throw response
      }

      Turbo.visit(this.signInPathValue)
    } catch (error) {
      Sentry.captureMessage('Logout error', {
        extra: { error: error },
      })

      toast('Something went wrong. Please try again.')
    }
  }
}

import { Controller } from '@hotwired/stimulus'
import Cookies from 'js-cookie'

/*
 * Description
 * -------
 *
 * Sets checkout message cookie.
 * Must be used on textarea element.
 * Can be used multiple times in the document.
 *
 * Usage
 * -------
 *
 * <textarea
 *   data-controller="checkout-message"
 *   data-action="input->checkout-message#setCookie"
 * ></textarea>
 */

const COOKIE_NAME = 'checkout_message'

export default class extends Controller {
  connect() {
    const cookie = Cookies.get(COOKIE_NAME)

    if (cookie) {
      this.element.value = JSON.parse(cookie).body
    }
  }

  setCookie(e) {
    if (e.target.value) {
      Cookies.set(COOKIE_NAME, JSON.stringify({ body: e.target.value }))
    } else {
      Cookies.remove(COOKIE_NAME)
    }
  }
}

import { Controller } from '@hotwired/stimulus'

/*
 * Description
 * -------
 *
 * Scrolls to top of the window.
 * Can be used on any element.
 * Can be used multiple times in the document.
 *
 * Usage
 * -------
 *
 * <button
 *   data-controller="scroll-to-top"
 *   data-action="click->scroll-to-top#scroll"
 * ></button>
 */

export default class extends Controller {
  scroll() {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }
}

export const pausePlayers = (source) => {
  Array.from(document.querySelectorAll('mux-player')).forEach((element) => {
    if (element !== source) {
      element.pause?.()
    }
  })

  Array.from(document.querySelectorAll('video, audio')).forEach((element) => {
    if (element !== source) {
      element.pause?.()
    }
  })

  Array.from(document.querySelectorAll('iframe[src*="youtube.com"]')).forEach((element) => {
    if (element !== source) {
      element.contentWindow.postMessage(
        JSON.stringify({ event: 'command', func: 'pauseVideo', args: '' }),
        '*',
      )
    }
  })

  Array.from(document.querySelectorAll('iframe[src*="vimeo.com"]')).forEach((element) => {
    if (element !== source) {
      element.contentWindow.postMessage(JSON.stringify({ method: 'pause' }), '*')
    }
  })
}

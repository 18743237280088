import { Controller } from '@hotwired/stimulus'

/*
 * Description
 * -------
 *
 * Changes and resets target "src" attribute value.
 * Can be used on any element.
 * Can be used multiple times in the document.
 *
 * Targets
 * -------
 *
 * element - (required) - element to change src attribute value
 *
 * Values
 * -------
 *
 * src - (required) - new src attribute value
 *
 * Optional methods
 * -------
 *
 * reset() - resets src attribute value to the original one
 *
 * Usage
 * -------
 *
 * <div
 *   data-controller="src-change"
 *   data-src-change-src-value="https://example.com"
 *   data-action="mouseover->src-change#change"
 * >
 *   <img src="" data-src-change-target="element" />
 * </div>
 */

export default class extends Controller {
  static targets = ['element']

  static values = { src: String }

  connect() {
    this.original = this.elementTarget.src
  }

  change() {
    this.elementTarget.src = this.srcValue
  }

  reset() {
    this.elementTarget.src = this.original
  }

  disconnect() {
    this.reset()
  }

  teardown() {
    this.reset()
  }
}

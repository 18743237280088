import SparkMD5 from 'spark-md5'

export const generateFileChecksum = (file) => {
  const blobSlice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice
  const chunkSize = 2097152 // 2 MB
  const chunksCount = Math.ceil(file.size / chunkSize)
  const spark = new SparkMD5.ArrayBuffer()
  let currentChunk = 0

  return new Promise((resolve, reject) => {
    const onFileReaderLoad = (e) => {
      spark.append(e.target.result)

      currentChunk = currentChunk + 1

      if (currentChunk < chunksCount) {
        loadChunk()
      } else {
        resolve(spark.end())
      }
    }

    const loadChunk = () => {
      const fileReader = new FileReader()
      const start = currentChunk * chunkSize
      const end = start + chunkSize >= file.size ? file.size : start + chunkSize

      fileReader.onload = onFileReaderLoad

      fileReader.onerror = reject

      fileReader.readAsArrayBuffer(blobSlice.call(file, start, end))
    }

    loadChunk()
  })
}

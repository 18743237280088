import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    maxFileSize: Number,
    avatarPlaceholderHtml: String,
    email: String,
  }
  static targets = [
    'avatarContainer',
    'avatarField',
    'removeAvatarField',
    'removeAvatarButton',
    'emailField',
    'submitModal',
  ]

  removeAvatar() {
    this.avatarContainerTarget.innerHTML = this.avatarPlaceholderHtmlValue
    this.avatarFieldTarget.value = ''
    this.removeAvatarFieldTarget.value = '1'
    this.removeAvatarButtonTarget.classList.add('hidden')
  }

  setAvatar(event) {
    if (this.validateFile(event.target)) {
      this.avatarContainerTarget.innerHTML = `<img src=${URL.createObjectURL(
        event.target.files[0],
      )} class="avatar avatar--xlarge" />`
      this.removeAvatarButtonTarget.classList.remove('hidden')
    }
  }

  validateFile(inputFile) {
    const file = inputFile.files[0]
    let sizeError = false

    if (file && file.size > this.maxFileSizeValue) {
      sizeError = true
      window.alert('This file exceeds the maximum allowed file size (5 MB)')
      inputFile.value = ''
    }

    return !sizeError
  }

  submit(event) {
    if (this.emailFieldTarget.value !== this.emailValue) {
      event.preventDefault()
      event.stopPropagation()

      this.submitModalTarget.classList.remove('hidden')
    }
  }
}

import { Controller } from '@hotwired/stimulus'
import Cookies from 'js-cookie'
import { addScrollLock, removeScrollLock } from './utils/scrollLock'

/*
 * Description
 * -------
 *
 * Handles adult modal.
 * Can be used on any element.
 * Should be use only once in the document.
 *
 * Values
 * -------
 *
 * cookieName - (required) - cookie name to store information if user has already seen the modal
 *
 * Usage
 * -------
 *
 * <div class="hidden" data-controller="ios-adult-modal" data-adult-modal-cookie-name-value="cookie_name">
 *   <div data-action="click->adult-modal#close">Close</div>
 * </div>
 */

export default class extends Controller {
  static values = { cookieName: String }

  connect() {
    if (!Cookies.get(this.cookieNameValue)) {
      addScrollLock()
    }
  }

  close() {
    this.element.classList.add('hidden')
    removeScrollLock()
    Cookies.set(this.cookieNameValue, true, { expires: 365 })
  }
}

export const disableFormElements = (form) => {
  if (!form.elements) return

  Array.from(form.elements).forEach((element) => {
    if (element.tagName === 'BUTTON') {
      element.dataset.loading = true
    } else {
      element.disabled = true
    }
  })
}

export const enableFormElements = (form) => {
  if (!form.elements) return

  Array.from(form.elements).forEach((element) => {
    if (element.tagName === 'BUTTON') {
      delete element.dataset.loading
    } else {
      element.disabled = false
    }
  })
}

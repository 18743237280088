import { Controller } from '@hotwired/stimulus'
import { useMatchMedia } from 'stimulus-use'

/*
 * Description
 * -------
 *
 * Adds CSS class to content element if it's height is higher than height value and shows button to expand content.
 * Can be used on any element.
 * Can be used multiple times in the document.
 *
 * Targets
 * -------
 *
 * - content - (required) - element to trim
 * - button  - (required) - element to expand content element
 *
 * Values
 * -------
 *
 * - height    - (required) - maximum content element height
 * - class     - (required) - class to add to trimmed content element
 * - height-sm - (optional) - maximum content element height for small screens
 *
 * Usage
 * -------
 *
 * <div
 *   data-controller="trim"
 *   data-trim-height-value="200"
 *   data-trim-class-value="trimmed"
 * >
 *   <div data-trim-target="content">Content to trim</div>
 *   <button data-trim-target="button" data-action="click->trim#expand" class="hidden"></button>
 * </div>
 */

export default class extends Controller {
  static targets = ['content', 'button']

  static values = { height: Number, heightSm: Number, class: String }

  connect() {
    if (this.hasHeightSmValue) {
      useMatchMedia(this, {
        mediaQueries: {
          small: '(max-width: 639px)',
        },
      })
    }
  }

  isSmall() {
    this.trim(this.heightSmValue)
  }

  notSmall() {
    this.trim(this.heightValue)
  }

  trim(height) {
    this.contentTarget.classList.remove(this.classValue)
    this.buttonTarget.classList.add('hidden')

    if (this.contentTarget.offsetHeight > height) {
      this.contentTarget.classList.add(this.classValue)
      this.buttonTarget.classList.remove('hidden')
    } else {
      this.contentTarget.classList.remove(this.trimmedClass)
      this.buttonTarget.classList.add('hidden')
    }
  }

  initialize() {
    this.trim(this.heightValue)
  }

  expand() {
    this.contentTarget.classList.remove(this.classValue)
    this.buttonTarget.classList.add('hidden')
  }
}

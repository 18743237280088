import { Turbo } from '@hotwired/turbo-rails'

Turbo.StreamActions.hide = function () {
  const target = this.getAttribute('target') || this.getAttribute('targets')

  document.querySelectorAll(`[id=${target}]`).forEach((element) => {
    element.classList.add('hidden')
  })
}

Turbo.StreamActions.show = function () {
  const target = this.getAttribute('target') || this.getAttribute('targets')

  document.querySelectorAll(`[id=${target}]`).forEach((element) => {
    element.classList.remove('hidden')
  })
}

Turbo.StreamActions.redirect = function () {
  Turbo.visit(this.target);
};

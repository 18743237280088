import { Controller } from '@hotwired/stimulus'

/*
 * Description
 * -------
 *
 * Handles infinite scroll.
 * Must be used on link element.
 * Should be used only once in the document.
 *
 * Usage
 * -------
 *
 * <a href="#" data-controller="infinite-scroll" data-turbo-stream="true"></a>
 */

export default class extends Controller {
  connect() {
    this.observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          this.element.click()
        }
      },
      {
        rootMargin: '0px 0px 2500px 0px',
      },
    )

    this.observer.observe(this.element)
  }

  disconnect() {
    this.observer?.unobserve(this.element)
  }
}

import { Controller } from '@hotwired/stimulus'
import Rails from '@rails/ujs'
import Cookies from 'js-cookie'

/*
 * Description
 * -------
 *
 * Handles popup which makes it impossible to see the app without a password.
 * Can be used on any element.
 * Should be used only once in the document.
 *
 * Targets
 * -------
 *
 * input - (required) - input element
 * error - (required) - error message element
 *
 * Values
 * -------
 *
 * password - (required) - password
 *
 * Usage
 * -------
 *
 * <div
 *   data-controller="mobile-password"
 *   data-mobile-password-password-value="fooBar"
 * >
 *   <form data-action="submit->mobile-password#submit">
 *     <input type="password" data-mobile-password-target="input" />
 *     <p class="hidden" data-mobile-password-target="error">Incorrect password</p>
 *     <button type="submit">Enter</button>
 *   </form>
 * </div>
 */

const COOKIE_NAME = 'MOBILE_PASSWORD_AUTHENTICATED'

export default class extends Controller {
  static targets = ['input', 'error']

  static values = { password: String }

  connect() {
    if (Cookies.get(COOKIE_NAME)) this.element.remove()
  }

  enter(e) {
    Rails.stopEverything(e)

    if (new FormData(e.target).get('password') === this.passwordValue) {
      this.element.remove()
      Cookies.set(COOKIE_NAME, true)
    } else {
      this.inputTarget.classList.add('error')
      this.errorTarget.classList.remove('hidden')
    }
  }
}

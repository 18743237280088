import Cookies from 'js-cookie'

const CUSTOM_TOKEN_COOKIE_NAME = 'custom_token'

export const getCustomToken = () => {
  return Cookies.get(CUSTOM_TOKEN_COOKIE_NAME)
}

export const removeCustomToken = () => {
  Cookies.remove(CUSTOM_TOKEN_COOKIE_NAME)
}
import Bowser from 'bowser'

export const isPWA = () => window.matchMedia('(display-mode: standalone)').matches

export const isDesktop = () => Bowser.getParser(navigator.userAgent).getPlatformType() === 'desktop'

export const isWLA = () => window.__fw_app__.mobileSession

export const isSafari = () =>
  navigator.userAgent.indexOf('Safari') > -1 && navigator.userAgent.indexOf('Chrome') <= -1

export const isProduction = () => window.__fw_app__.environment === 'production'

export const isDevelopment = () => window.__fw_app__.environment === 'development'

export const environment = () => window.__fw_app__.environment

export const vapidKey = () => window.__fw_app__.vapidKey

export const feature = (feature) => !!window.__fw_app__.features[feature]

import { Turbo } from '@hotwired/turbo-rails'

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.onmessage = (event) => {
    if (event.data.messageType === 'notification-clicked') {
      if (location.href !== event.data.notification.click_action) {
        Turbo.visit(event.data.notification.click_action)
      }
    }
  }
}

import { getAuth } from 'firebase/auth'
import { isWLA } from '../../controllers/supporters-app/utils/dev'
import {
  disableFormElements,
  enableFormElements,
} from '../../controllers/supporters-app/utils/form'
import { removeScrollLock } from '../../controllers/supporters-app/utils/scrollLock'
import {
  registerServiceWorker,
  unregisterServiceWorker,
} from '../../controllers/supporters-app/utils/serviceWorker'
import { firebaseSignIn } from './firebase'

document.addEventListener('turbo:frame-missing', (event) => {
  if (event.detail.response.redirected) {
    event.preventDefault()
    event.detail.visit(event.detail.response)
  }
})

document.addEventListener('turbo:before-cache', () => {
  Array.from(document.querySelectorAll('.modal')).forEach((modal) => {
    modal.classList.add('hidden')
  })

  removeScrollLock()
  document.documentElement.removeAttribute('aria-loading')

  window.Stimulus.controllers.forEach((controller) => {
    if (typeof controller.teardown === 'function') {
      controller.teardown()
    }
  })
})

document.addEventListener('turbo:before-visit', (event) => {
  if (!isWLA()) {
    firebaseSignIn()
  }
  if (window.Android?.handleRedirect(event.detail.url)) {
    event.preventDefault()
  }
})

document.addEventListener('turbo:submit-start', (e) => {
  disableFormElements(e.target)
})

document.addEventListener('turbo:submit-end', (e) => {
  enableFormElements(e.target)
})

import { Controller } from '@hotwired/stimulus'

/*
 * Description
 * -------
 *
 * Displays window confirm when user tries to leave page when form is dirty.
 * Must be used on form element.
 * Can be used multiple times in the document.
 *
 * Optional methods
 * -------
 *
 * forceDirty()  - sets dirty to true
 * removeDirty() - sets dirty to false
 *
 * Usage
 * -------
 *
 * <form
 *   data-controller="unsaved-changes"
 *   data-action="input->unsaved-changes#setDirty beforeunload@window->unsaved-changes#block turbo:before-visit@window->unsaved-changes#block"
 * ></form>
 */

const MESSAGE = 'You have attempted to leave this page. Changes that you made may not be saved.'

export default class extends Controller {
  static targets = ['form']

  connect() {
    this.dirty = false
    this.initialFormData = this.formData
  }

  forceDirty() {
    this.dirty = true
  }

  removeDirty() {
    this.dirty = false
  }

  setDirty() {
    this.dirty = this.initialFormData !== this.formData
  }

  block(event) {
    if (this.dirty) {
      if (event.type === 'turbo:before-visit') {
        if (!window.confirm(MESSAGE)) {
          event.preventDefault()
        }
      } else {
        event.returnValue = MESSAGE

        return event.returnValue
      }
    }
  }

  get formData() {
    if (this.hasFormTarget) {
      return new URLSearchParams(new FormData(this.formTarget)).toString()
    }

    return new URLSearchParams(new FormData(this.element)).toString()
  }
}

import { Controller } from '@hotwired/stimulus'

/*
 * Description
 * -------
 *
 * Copies specified value to clipboard on element click.
 * Must be used on button element.
 * Can be used multiple times in the document.
 *
 * Usage
 * -------
 *
 * <button
 *   data-controller="clipboard"
 *   data-clipboard-text="Text to copy"
 * ></button>
 */

export default class extends Controller {
  async connect() {
    const { default: ClipboardJS } = await import('clipboard')
    const { default: tippy } = await import('tippy.js')

    this.clipboard = new ClipboardJS(this.element)
    this.clipboard.on('success', () => {
      tippy(this.element, {
        content: 'Copied!',
        showOnCreate: true,
        onHidden: (instance) => {
          instance.destroy()
        },
      })
    })
  }
}

import {init as initApm} from '@elastic/apm-rum'

window.initializeElasticRum = (enabled, environment, serverUrl, transactionSampleRate, disableInstrumentations, userId, userEmail) => {
  window.elasticApm = initApm({
    serviceName: 'membership',
    environment: environment,
    active: enabled,
    transactionSampleRate: transactionSampleRate,
    serverUrl: serverUrl,
    disableInstrumentations: disableInstrumentations,
    distributedTracingOrigins: [
      'https://stream.mux.com',
      'https://image.mux.com'
    ]
  });

  window.elasticApm.setUserContext({
    id: userId,
    email: userEmail
  });
}

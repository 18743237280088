import { Controller } from '@hotwired/stimulus'
import { Turbo } from '@hotwired/turbo-rails'
import { useDebounce } from 'stimulus-use'
import {csrfTokenHeaders} from "./utils/fetch";

/*
 * Description
 * -------
 *
 * Handles login.
 * Must be used on form element.
 * Can be used multiple times in the document.
 *
 * Values
 * -------
 *
 * loginWithFourthwallPath - (required) - API endpoint to login with Fourthwall
 *
 * Usage
 * -------
 *
 * <form
 *   data-controller="login"
 *   data-login-login-with-fourthwall-path-value="https://example.com"
 * >
 *   <input
 *     name="email"
 *     type="email"
 *     data-action="blur->login#loginWithFourthwall input->login#loginWithFourthwall"
 *   />
 *   <input name="password" type="password" />
 *   <button type="submit">Login</button>
 * </form>
 */

export default class extends Controller {
  static values = { loginWithFourthwallPath: String }

  static debounces = ['loginWithFourthwall']

  connect() {
    setTimeout(() => {
      Turbo.cache.clear()
    }, 1)

    useDebounce(this, {
      wait: 300,
    })
  }

  async loginWithFourthwall(e) {
    const response = await fetch(this.loginWithFourthwallPathValue, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'text/vnd.turbo-stream.html',
        ...csrfTokenHeaders()
      },
      body: JSON.stringify({ email: e.target.value }),
    })

    if (response.ok) {
      response.text().then(Turbo.renderStreamMessage)
    }
  }
}

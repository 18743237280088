import { Controller } from '@hotwired/stimulus'
import scrollIntoView from 'smooth-scroll-into-view-if-needed'

/*
 * Description
 * -------
 *
 * Scrolls to element on controller connect.
 * Can be used on any element.
 * Should be used only once in the document.
 *
 * Usage
 * -------
 *
 * <div data-controller="scroll-to-element"></div>
 */

export default class extends Controller {
  connect() {
    setTimeout(() => {
      scrollIntoView(this.element)
    })
  }
}

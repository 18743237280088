import { Controller } from '@hotwired/stimulus'

/*
 * Description
 * -------
 *
 * Handles WLA downloads page open.
 * Can be used on any element.
 * Can be used multiple times in the document.
 *
 * Usage
 * -------
 *
 * <button
 *   data-controller="downloads-link"
 *   data-action="click->downloads-link#handleClick"
 * >
 *  Downloads
 * </button>
 */

export default class extends Controller {
  toggleIcon(e) {
    this.element.classList.remove('hidden')

    if (e.detail.visible) {
      this.element.style.marginRight = '48px'
    } else {
      this.element.style.marginRight = '0px'
    }
  }
}

import { Turbo } from '@hotwired/turbo-rails'
import { feature, isPWA, isSafari } from '../../controllers/supporters-app/utils/dev'

let PullJsInstance = null

var _SETTINGS = {}
var defaultStyle = void 0
var defaultHTML = void 0
var _defaults = {
  distThreshold: 80,
  distMax: 100,
  distReload: 40,
  bodyOffset: 20,
  mainElement: 'body',
  triggerElement: 'body',
  ptrElement: '.ptr',
  classPrefix: 'ptr--',
  cssProp: 'min-height',
  containerClassName: '',
  boxClassName: '',
  contentClassName: '',
  textClassName: '',
  instructionsPullToRefresh: 'Pull down to refresh',
  instructionsReleaseToRefresh: 'Release to refresh',
  instructionsRefreshing: 'Refreshing',
  refreshTimeout: 0,
  onInit: function onInit() {},
  onRefresh: function onRefresh() {
    return location.reload()
  },
  resistanceFunction: function resistanceFunction(t) {
    return Math.min(1, t / 4)
  },
}

var pullStartY = null
var pullMoveY = null
var dist = 0
var distResisted = 0

var _disabled = false
var _state = 'pending'
var _setup = false
var _enable = false
var _timeout = void 0

function _update() {
  var _SETTINGS2 = _SETTINGS,
    classPrefix = _SETTINGS2.classPrefix,
    ptrElement = document.querySelector('.ptr--ptr'),
    instructionsRefreshing = _SETTINGS2.instructionsRefreshing,
    instructionsPullToRefresh = _SETTINGS2.instructionsPullToRefresh,
    instructionsReleaseToRefresh = _SETTINGS2.instructionsReleaseToRefresh

  var textEl = ptrElement.querySelector('.' + classPrefix + 'text')

  if (_state === 'releasing') {
    // textEl.innerHTML = instructionsReleaseToRefresh
    textEl.style.transform = 'rotate(180deg)'
  }

  if (_state === 'pulling' || _state === 'pending') {
    // textEl.innerHTML = instructionsPullToRefresh
    textEl.style.transform = 'rotate(0deg)'
  }

  if (_state === 'refreshing') {
    textEl.innerHTML =
      '<svg class="loader loader--medium" viewBox="0 0 100 100"><circle class="loader__circle" cx="50" cy="50" r="45" /></svg>'
  }
}

function _setupEvents() {
  function onReset() {
    var _SETTINGS3 = _SETTINGS,
      cssProp = _SETTINGS3.cssProp,
      ptrElement = _SETTINGS3.ptrElement,
      classPrefix = _SETTINGS3.classPrefix

    ptrElement.classList.remove(classPrefix + 'refresh')
    ptrElement.style[cssProp] = '0px'

    _state = 'pending'
  }

  function _onTouchStart(e) {
    onReset()
    _disabled = false

    if (document.documentElement.classList.contains('scroll-lock')) {
      _disabled = true
    }

    if (_disabled) {
      return
    }

    if (!document.querySelector('.ptr--ptr')) {
      var ptr = document.createElement('div')
      document.body.insertBefore(ptr, document.body.firstChild)

      ptr.classList.add('ptr--' + 'ptr')
      ptr.innerHTML = defaultHTML
    }

    var _SETTINGS4 = _SETTINGS,
      // triggerElement = _SETTINGS4.triggerElement
      triggerElement = document.body

    if (!window.scrollY) {
      pullStartY = e.touches[0].screenY
    }

    if (_state !== 'pending') {
      return
    }

    clearTimeout(_timeout)

    _enable = triggerElement.contains(e.target)
    _state = 'pending'
    _update()
  }

  function _onTouchMove(e) {
    if (_disabled) {
      return
    }

    var _SETTINGS5 = _SETTINGS,
      ptrElement = document.querySelector('.ptr--ptr'),
      resistanceFunction = _SETTINGS5.resistanceFunction,
      distMax = _SETTINGS5.distMax,
      distThreshold = _SETTINGS5.distThreshold,
      cssProp = _SETTINGS5.cssProp,
      classPrefix = _SETTINGS5.classPrefix

    if (!pullStartY) {
      if (!window.scrollY) {
        pullStartY = e.touches[0].screenY
      }
    } else {
      pullMoveY = e.touches[0].screenY
    }

    if (!_enable || _state === 'refreshing') {
      if (!window.scrollY && pullStartY < pullMoveY) {
        // e.preventDefault()
      }

      return
    }

    if (_state === 'pending') {
      ptrElement.classList.add(classPrefix + 'pull')
      _state = 'pulling'
      _update()
    }

    if (pullStartY && pullMoveY) {
      dist = pullMoveY - pullStartY
    }

    if (dist > 20) {
      // e.preventDefault()

      ptrElement.style[cssProp] = distResisted + 'px'

      distResisted = resistanceFunction(dist / distThreshold) * Math.min(distMax, dist)

      if (_state === 'pulling' && distResisted > distThreshold) {
        ptrElement.classList.add(classPrefix + 'release')
        _state = 'releasing'
        _update()
      }

      if (_state === 'releasing' && distResisted < distThreshold) {
        ptrElement.classList.remove(classPrefix + 'release')
        _state = 'pulling'
        _update()
      }
    }
  }

  function _onTouchEnd() {
    if (_disabled) {
      return
    }

    var _SETTINGS6 = _SETTINGS,
      ptrElement = document.querySelector('.ptr--ptr'),
      onRefresh = _SETTINGS6.onRefresh,
      refreshTimeout = _SETTINGS6.refreshTimeout,
      distThreshold = _SETTINGS6.distThreshold,
      distReload = _SETTINGS6.distReload,
      cssProp = _SETTINGS6.cssProp,
      classPrefix = _SETTINGS6.classPrefix

    if (_state === 'releasing' && distResisted > distThreshold) {
      _state = 'refreshing'

      ptrElement.style[cssProp] = distReload + 'px'
      ptrElement.classList.add(classPrefix + 'refresh')

      _timeout = setTimeout(function () {
        var retval = onRefresh(onReset)
        // if (retval && typeof retval.then === 'function') {
        //   retval.then(function () {
        //     return onReset()
        //   })
        // }
        // if (!retval && !onRefresh.length) {
        //   onReset()
        // }
      }, refreshTimeout)
    } else {
      if (_state === 'refreshing') {
        return
      }

      ptrElement.style[cssProp] = '0px'

      _state = 'pending'
    }

    _update()

    ptrElement.classList.remove(classPrefix + 'release')
    ptrElement.classList.remove(classPrefix + 'pull')

    pullStartY = pullMoveY = null
    dist = distResisted = 0
  }

  window.addEventListener('touchend', _onTouchEnd)
  window.addEventListener('touchstart', _onTouchStart)
  window.addEventListener('touchmove', _onTouchMove, { passive: false })

  // Store event handlers to use for teardown later
  return {
    onTouchStart: _onTouchStart,
    onTouchMove: _onTouchMove,
    onTouchEnd: _onTouchEnd,
  }
}

function _run() {
  var _SETTINGS7 = _SETTINGS,
    mainElement = _SETTINGS7.mainElement,
    classPrefix = _SETTINGS7.classPrefix,
    onInit = _SETTINGS7.onInit,
    containerClassName = _SETTINGS7.containerClassName

  if (!_SETTINGS.ptrElement) {
    var ptr = document.createElement('div')
    if (mainElement !== document.body) {
      mainElement.parentNode.insertBefore(ptr, mainElement)
    } else {
      document.body.insertBefore(ptr, document.body.firstChild)
    }

    ptr.classList.add(classPrefix + 'ptr')
    if (containerClassName !== '') {
      ptr.classList.add('' + containerClassName)
    }
    ptr.innerHTML = defaultHTML
    _SETTINGS.ptrElement = ptr
  }

  if (typeof onInit === 'function') {
    onInit(_SETTINGS)
  }

  return {
    ptrElement: _SETTINGS.ptrElement,
  }
}

var updateElement = function updateElement() {
  defaultHTML =
    '\n    <div class="' +
    _SETTINGS.classPrefix +
    'box' +
    (_SETTINGS.boxClassName ? ' ' + _SETTINGS.boxClassName : '') +
    '">\n      <div class="' +
    _SETTINGS.classPrefix +
    'content' +
    (_SETTINGS.contentClassName ? ' ' + _SETTINGS.contentClassName : '') +
    '">\n        <div class="' +
    _SETTINGS.classPrefix +
    'text' +
    (_SETTINGS.textClassName ? ' ' + _SETTINGS.textClassName : '') +
    '">' +
    '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 32 33" fill="none" role="img"><path d="m15.922 31 .053-30M1.948 16.96 15.92 30.994l14.027-14.088" stroke="currentColor" stroke-width="3"></path></svg>' +
    '</div>\n      </div>\n    </div>\n  '
}

var Pull = {
  init: function init() {
    window.addEventListener(
      'scroll',
      (e) => {
        if (e.target.body || e.target === document.body || e.target === document.documentElement)
          return

        if (window.getComputedStyle(e.target).getPropertyValue('overflow-x') === 'scroll') {
          _disabled = true
        }

        if (window.getComputedStyle(e.target).getPropertyValue('overflow-y') === 'scroll') {
          _disabled = true
        }
      },
      true,
    )

    window.addEventListener(
      'scrollend',
      () => {
        _disabled = false
      },
      true,
    )

    document.documentElement.style.overscrollBehavior = 'none'

    var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {}

    var handlers = void 0
    Object.keys(_defaults).forEach(function (key) {
      _SETTINGS[key] = options[key] || _defaults[key]
    })

    if (typeof _SETTINGS.mainElement === 'string') {
      _SETTINGS.mainElement = document.body
    }

    if (typeof _SETTINGS.ptrElement === 'string') {
      // _SETTINGS.ptrElement = document.querySelector(_SETTINGS.ptrElement)
      _SETTINGS.ptrElement = document.querySelector('.ptr--ptr')
    }

    if (typeof _SETTINGS.triggerElement === 'string') {
      _SETTINGS.triggerElement = document.querySelector(_SETTINGS.triggerElement)
    }

    updateElement()

    if (!_setup) {
      handlers = _setupEvents()
      _setup = true
    }

    var _run2 = _run(),
      ptrElement = _run2.ptrElement

    return {
      destroy: function destroy() {
        PullJsInstance = null
        // Teardown event listeners
        window.removeEventListener('touchstart', handlers.onTouchStart)
        window.removeEventListener('touchend', handlers.onTouchEnd)
        window.removeEventListener('touchmove', handlers.onTouchMove)

        // Remove ptr element and style tag
        ptrElement.parentNode.removeChild(ptrElement)

        // Enable setupEvents to run again
        _setup = false

        // null object references
        handlers = null
        ptrElement = null
        _SETTINGS = {}
      },
    }
  },
}

if (feature('pwa') && isPWA() && isSafari()) {
  document.addEventListener('turbo:load', () => {
    if (window.location.pathname.indexOf('/supporters/messages') > -1) {
      PullJsInstance?.destroy()
    } else {
      if (!PullJsInstance) {
        PullJsInstance = Pull.init({ onRefresh: () => Turbo.visit(window.location) })
      }
    }
  })
}

import { Controller } from '@hotwired/stimulus'

const LOCAL_STOREAGE_KEY = 'wla_download_list'

export default class extends Controller {
  static targets = [
    'data',
    'download',
    'downloaded',
    'downloadingWithProgress',
    'downloadingWithoutProgress',
    'progress',
  ]

  static values = { id: Number }

  connect() {
    if (localStorage.getItem(LOCAL_STOREAGE_KEY)) {
      this.showStatus(JSON.parse(localStorage.getItem(LOCAL_STOREAGE_KEY)))
    }
  }

  handleEvent(e) {
    localStorage.setItem(LOCAL_STOREAGE_KEY, JSON.stringify(e.detail))

    this.showStatus(e.detail)
  }

  showStatus(data) {
    this.enableDownload()

    const status = data.find((status) => status.postId === this.idValue)

    if (!status) return this.showDownload()

    if (status.status === 'downloaded') return this.showDownloaded()
    if (status.status === 'downloading') {
      if (status.progress === -1) return this.showDownloadingWithoutProgress()

      this.setProgress(status.progress * 100)
      return this.showDownloadingWithProgress()
    }
  }

  handleDownloadClick() {
    window.webkit?.messageHandlers?.storeOffline?.postMessage?.(JSON.parse(this.mediaData))
    window.Android?.storeOffline?.(this.mediaData)
  }

  handleDownloadingClick() {
    window.webkit?.messageHandlers?.manageDownloadingOffline?.postMessage?.(
      JSON.parse(this.mediaData),
    )
    window.Android?.handleDownloadingButtonClick?.(this.mediaData)
  }

  handleDownloadedClick() {
    window.webkit?.messageHandlers?.manageDownloadedOffline?.postMessage?.(
      JSON.parse(this.mediaData),
    )
    window.Android?.handleDownloadedButtonClick?.(this.mediaData)
  }

  enableDownload() {
    this.downloadTarget.disabled = false
  }

  showDownload() {
    this.downloadTarget.classList.remove('hidden')
    this.downloadedTarget.classList.add('hidden')
    this.downloadingWithProgressTarget.classList.add('hidden')
    this.downloadingWithoutProgressTarget.classList.add('hidden')
  }

  showDownloaded() {
    this.downloadTarget.classList.add('hidden')
    this.downloadedTarget.classList.remove('hidden')
    this.downloadingWithProgressTarget.classList.add('hidden')
    this.downloadingWithoutProgressTarget.classList.add('hidden')
  }

  showDownloadingWithProgress() {
    this.downloadTarget.classList.add('hidden')
    this.downloadedTarget.classList.add('hidden')
    this.downloadingWithProgressTarget.classList.remove('hidden')
    this.downloadingWithoutProgressTarget.classList.add('hidden')
  }

  showDownloadingWithoutProgress() {
    this.downloadTarget.classList.add('hidden')
    this.downloadedTarget.classList.add('hidden')
    this.downloadingWithProgressTarget.classList.add('hidden')
    this.downloadingWithoutProgressTarget.classList.remove('hidden')
  }

  setProgress(progress) {
    this.progressTarget.style.setProperty('--progress', progress + '%')
  }

  get mediaData() {
    return this.dataTarget.innerHTML
  }
}

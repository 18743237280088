import { Controller } from '@hotwired/stimulus'
import Rails from '@rails/ujs'
import mailcheck from 'mailcheck'

/*
 * Description
 * -------
 *
 * Validates email and shows suggestion.
 * Can be used on any element.
 * Can be used multiple times in the document.
 *
 * Targets
 * -------
 *
 * input      - (required) - input element to validate
 * suggestion - (required) - element containing the suggestion
 * email      - (required) - element where suggested email will be displayed
 *
 * Usage
 * -------
 *
 * <div data-controller="mailcheck">
 *  <input data-mailcheck-target="input" data-action="focusout->mailcheck#verify input->mailcheck#validate" />
 *  <div data-mailcheck-target="suggestion" class="hidden">
 *    Did you mean <div data-mailcheck-target="email"></div> ?
 *    <div data-action="click->mailcheck#apply">Update email</div>
 *  </div>
 * </div>
 */

export default class extends Controller {
  static targets = ['input', 'suggestion', 'email']

  showSuggestion() {
    this.suggestionTarget.classList.remove('hidden')
  }

  hideSuggestion() {
    this.suggestionTarget.classList.add('hidden')
  }

  setInvalid() {
    this.inputTarget.setCustomValidity('An email has invalid domain.')
  }

  removeInvalid() {
    this.inputTarget.setCustomValidity('')
  }

  validate() {
    const domain = this.inputTarget.value.split('@')[1]

    const knownDomains = ['gmail.com', 'ymail.com', 'icloud.com']
    const restrictedDomains = ['.con', 'yahoo.co', 'hotmail.co', 'aol.co']

    for (const index in knownDomains) {
      if (domain?.startsWith(knownDomains[index].split('.')[0]) && domain !== knownDomains[index]) {
        return this.setInvalid()
      }
    }

    if (restrictedDomains.some((restrictedDomain) => domain?.endsWith(restrictedDomain))) {
      return this.setInvalid()
    }

    this.removeInvalid()
  }

  verify() {
    mailcheck.run({
      email: this.inputTarget.value.trim(),
      suggested: (suggestion) => {
        this.emailTarget.innerHTML = suggestion.full
        this.showSuggestion()
      },
      empty: () => this.hideSuggestion(),
    })
  }

  apply() {
    mailcheck.run({
      email: this.inputTarget.value.trim(),
      suggested: (suggestion) => {
        this.inputTarget.value = suggestion.full
        Rails.fire(this.inputTarget, 'input')
        this.hideSuggestion()
      },
    })
  }
}

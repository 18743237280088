import { Controller } from '@hotwired/stimulus'
import { isDesktop } from './utils/dev'

/*
 * Description
 * -------
 *
 * Handles emoji picker.
 * Can be used on any element.
 * Can be used multiple times in the document.
 *
 * Targets
 * -------
 *
 * - input  - (required) - comment input element
 * - button - (required) - element that toggles emoji picker
 * - container - (required) - element containing emoji picker
 *
 * Usage
 * -------
 *
 * <div data-controller="emoji-picker">
 *   <div data-emoji-picker-target="container"></div>
 *   <input data-emoji-picker-target="input" />
 *   <button data-emoji-picker-target="button" data-action="click->emoji-picker#toggle">
 * </div>
 */

export default class extends Controller {
  static targets = ['input', 'button', 'container']

  async connect() {
    if (!isDesktop()) return

    this.buttonTarget.classList.remove('hidden')
    this.inputTarget.classList.add('textarea--comment-with-emoji')

    const { createPopper } = await import('@popperjs/core')
    const { Picker } = await import('emoji-mart')
    const { data } = await import('@emoji-mart/data')

    const emojiPicker = new Picker({
      data,
      theme: 'light',
      skinTonePosition: 'none',
      onClickOutside: (e) => {
        if (e.target !== this.buttonTarget) {
          this.hide()
        }
      },
      previewPosition: 'none',
      onEmojiSelect: (e) => {
        this.inputTarget.value += e.native
        this.hide()
        this.inputTarget.dispatchEvent(new Event('input'))
        this.inputTarget.focus()
      },
    })

    this.containerTarget.appendChild(emojiPicker)

    this.popperInstance = createPopper(this.buttonTarget, this.containerTarget, {
      placement: 'top',
      modifiers: [
        {
          name: 'computeStyles',
          options: {
            gpuAcceleration: false,
          },
        },
        {
          name: 'offset',
          options: {
            offset: [0, 16],
          },
        },
      ],
    })
  }

  hide() {
    this.containerTarget.classList.add('hidden')
    this.popperInstance?.update()
    this.isEmojiPickerOpen = false
  }

  show() {
    this.containerTarget.classList.remove('hidden')
    this.isEmojiPickerOpen = true
    this.popperInstance?.update()
  }

  toggle() {
    if (this.isEmojiPickerOpen) {
      this.hide()
    } else {
      this.show()
    }
  }

  disconnect() {
    this.popperInstance?.destroy()
  }
}

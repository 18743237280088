import { Controller } from '@hotwired/stimulus'

/*
+ * Description
+ * -------
+ *
+ * Reloads turbo-frame element in interval by setting its src attribute.
+ * Must be used on turbo-frame element.
+ * Can be used multiple times in the document.
+ *
+ * Values
+ * -------
+ *
+ * delay - (optional) - delay in milliseconds
+ *
+ * Usage
+ * -------
+ *
+ * <turbo-frame
+ *   data-src="https://example.com"
+ *   data-controller="polling"
+ * ></turbo-frame>
+ */

export default class extends Controller {
  static values = { src: String, delay: { type: Number, default: 5000 } }

  connect() {
    this.timeout = setTimeout(() => {
      this.element.src = this.srcValue
      this.element.style.opacity = 1
    }, this.delayValue)
  }

  disconnect() {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
  }
}

import { Controller } from '@hotwired/stimulus'
import Cookies from 'js-cookie'
import { isPWA } from './utils/dev'
import { addScrollLock, removeScrollLock } from './utils/scrollLock'

const COOKIE_NAME = 'hide_ios_update_modal'

/*
 * Description
 * -------
 *
 * Handles iOS update modal for PWA.
 * Can be used on any element.
 * Should be use only once in the document.
 *
 * Usage
 * -------
 *
 * <div class="hidden" data-controller="ios-update-modal">
 *   <div data-action="click->ios-update-modal#close">Close</div>
 * </div>
 */

export default class extends Controller {
  connect() {
    if (!Cookies.get(COOKIE_NAME) && isPWA()) {
      setTimeout(() => {
        this.element.classList.remove('hidden')
        addScrollLock()
      }, 1000)
    }
  }

  close() {
    this.element.classList.add('hidden')
    removeScrollLock()
    Cookies.set(COOKIE_NAME, true, { expires: 7 })
  }
}

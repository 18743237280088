import { Controller } from '@hotwired/stimulus'

/*
 * Description
 * -------
 *
 * Handles flash message.
 * Can be used on any element.
 * Can be used multiple times in the document.
 *
 * Usage
 * -------
 *
 * <div data-controller="flash">
 *   <button data-action="click->flash#remove">Remove</button>
 * </div>
 */

export default class extends Controller {
  connect() {
    this.timeout = setTimeout(() => {
      this.remove()
    }, 2500)
  }

  disconnect() {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
  }

  remove() {
    this.element.remove()
  }
}

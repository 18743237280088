import { Controller } from '@hotwired/stimulus'
import { Turbo } from '@hotwired/turbo-rails'

/*
 * Description
 * -------
 *
 * Fixes a bug when Turbo initiates a restoration visit (when user navigate with the browser’s Back button)
 * and turbo frames with "turbo-action=advance" attribute stays busy.
 * Must be used on turbo-frame element.
 * Can be used multiple times in the document.
 *
 * Usage
 * -------
 *
 * <turbo-frame
 *   turbo-action="advance"
 *   data-controller="turboframe-history-fix"
 * ></turbo-frame>
 */

export default class extends Controller {
  connect() {
    if (Turbo.navigator.currentVisit?.action === 'restore' && this.element.hasAttribute('busy')) {
      this.element.src = window.location.href
    }
  }
}

import { Controller } from '@hotwired/stimulus'
import { useMatchMedia } from 'stimulus-use'
import { toggleScrollLock } from './utils/scrollLock'

/*
 * Description
 * -------
 *
 * Handles drawer.
 * Can be used on any element.
 * Should be used only once in the document.
 *
 * Targets
 * -------
 *
 * drawer - (required) - element containing drawer
 *
 * Usage
 * -------
 *
 * <div data-controller="drawer">
 *   <div data-action="click->drawer#toggle"></div>
 *
 *   <div data-drawer-target="drawer">
 *     <div data-action="click->drawer#toggle">Close</div>
 *     Drawer content
 *   </div>
 * </div>
 */

export default class extends Controller {
  static targets = ['drawer']

  connect() {
    useMatchMedia(this, {
      mediaQueries: {
        small: '(min-width: 640px)',
      },
    })
  }

  toggle() {
    this.drawerTarget.classList.toggle('open')
    toggleScrollLock()
  }

  isSmall() {
    if (this.drawerTarget.classList.contains('open')) {
      this.toggle()
    }
  }
}

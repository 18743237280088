import { Controller } from '@hotwired/stimulus'
import { useDebounce } from 'stimulus-use'
import { pausePlayers } from './utils/pausePlayers'

/*
 * Description
 * -------
 *
 * Handles mux-player.
 * Must be user on mux-player element.
 * Can be used multiple times in the document.
 *
 * Values
 * -------
 *
 * time                   - (required) - saved time
 * saveTime               - (required) - wether save time
 * imesPath               - (required) - API endpoint to create and update time
 * finishTimesPath        - (required) - API endpoint to update times on finish
 * title                  - (required) - title
 * creatorName            - (required) - creator name
 * mediaSessionArtwork    - (required) - media session artwork
 *
 * Usage
 * -------
 *
 * <mux-player
 *   data-controller="mux-player"
 *   data-mux-player-time-value="0"
 *   data-mux-player-save-time-value="true"
 *   data-mux-player-times-path-value="https://example.com"
 *   data-mux-player-finish-times-path-value="https://example.com"
 *   data-mux-player-title-value="Video title"
 *   data-mux-player-creator-name-value="Creator name"
 *   data-mux-player-media-session-artwork-value="https://example.com"
 * >
 * </mux-player>
 */

export default class extends Controller {
  static debounces = ['updateTimeOnSeek', 'createTime']

  static values = {
    time: Number,
    saveTime: Boolean,
    timesPath: String,
    finishTimesPath: String,
    title: String,
    creatorName: String,
    mediaSessionArtwork: String,
  }

  async connect() {
    // Must be imported before any other mux-player import
    await import('../../src/supporters-app/media-theme-fourthwall-audio')
    await import('../../src/supporters-app/media-theme-fourthwall-audio-message')
    await import('@mux/mux-player')

    useDebounce(this, {
      wait: 2000,
    })

    this.element.addEventListener('play', () => {
      this.createMediaSession()
      pausePlayers(this.element)
      window.elasticApm.addLabels(this.extractPlayerAttributes())
    })

    this.element.addEventListener(
      'canplay',
      () => {
        this.createTime()
      },
      { once: true },
    )

    this.element.addEventListener('seeked', () => {
      this.updateTimeOnSeek()
    })

    this.element.addEventListener('ended', () => {
      this.updateTimeOnFinish()
    })

    this.element.addEventListener('error', (event) => {
      try {
        window.elasticApm.addLabels(this.flattenObject(event.detail.data))
        window.elasticApm.captureError(event.detail)
      } catch (error) {
        window.elasticApm.setCustomContext(JSON.stringify(event.detail))
        window.elasticApm.captureError(error)
        window.elasticApm.captureError(new Error(event.detail.toString()))
      }
    })
  }

  createMediaSession() {
    if ('mediaSession' in navigator && window.MediaMetadata) {
      navigator.mediaSession.metadata = new MediaMetadata({
        title: this.titleValue,
        artist: this.creatorNameValue,
        artwork: [
          {
            src: this.mediaSessionArtworkValue,
            sizes: '128x128',
            type: 'image/png',
          },
        ],
      })
    }
  }

  async createTime() {
    if (!this.saveTimeValue) return

    if (this.timeValue === -1) {
      const response = await fetch(this.timesPathValue, { method: 'POST' })

      if (!response.ok) return

      this.scheduleUpdateTime()
    } else {
      this.scheduleUpdateTime()
    }
  }

  scheduleUpdateTime() {
    this.updateTimeInterval = setInterval(() => {
      if (this.element.paused) return

      this.updateTime()
    }, 2000)
  }

  async updateTime() {
    const seconds = Math.floor(this.element.currentTime)

    if (seconds <= 0) return

    await fetch(this.timesPathValue, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ seconds: seconds }),
    })
  }

  updateTimeOnSeek() {
    if (!this.updateTimeInterval) return
    if (!this.element.paused) return

    this.updateTime()
  }

  async updateTimeOnFinish() {
    const seconds = Math.floor(this.element.currentTime)

    if (!this.hasFinishTimesPathValue) return
    if (!this.updateTimeInterval) return
    if (seconds <= 0) return

    if (seconds > 0) {
      await fetch(this.finishTimesPathValue, {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ seconds: seconds }),
      })
    }
  }

  disconnect() {
    if (!this.updateTimeInterval) return

    clearInterval(this.updateTimeInterval)
  }

  flattenObject(obj, parentKey = '', result = {}) {
    Object.entries(obj).forEach(([key, value]) => {
      const newKey = parentKey ? `${parentKey}.${key}` : key;
      if (typeof value === 'object' && value !== null) {
        this.flattenObject(value, newKey, result);
      } else if (typeof value !== 'function') {
        result[newKey] = value;
      }
    });
    return result;
  }

  extractPlayerAttributes() {
    return Object.fromEntries(
      Array.from(this.element.attributes).map((attribute) => [attribute.nodeName, attribute.nodeValue])
    )
  }
}

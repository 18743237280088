import { Controller } from '@hotwired/stimulus'
import { getApps } from 'firebase/app'
import { getMessaging, getToken } from 'firebase/messaging'
import Cookies from 'js-cookie'
import { isPWA, vapidKey } from './utils/dev'
import { addScrollLock, removeScrollLock } from './utils/scrollLock'

const COOKIE_NAME = 'hide_pwa_notification_modal'

/*
 * Description
 * -------
 *
 * Handles PWA notification modal.
 * Can be used on any element.
 * Should be use only once in the document.
 *
 * Usage
 * -------
 *
 * <div class="modal hidden" data-controller="notification-modal">
 *   <div data-action="click->notification-modal#deny">Deny</div>
 *   <div data-action="click->notification-modal#registerMessaging click->notification-modal#accept">Accept</div>
 * </div>
 */

export default class extends Controller {
  displayModal() {
    if (Notification.permission === 'granted') {
      return this.registerMessaging()
    }

    if (!Cookies.get(COOKIE_NAME) && Notification.permission !== 'denied') {
      this.element.classList.remove('hidden')
      addScrollLock()
    }
  }

  registerMessaging() {
    navigator.serviceWorker.getRegistrations().then((registrations) => {
      const messaging = getMessaging()

      getToken(messaging, {
        serviceWorkerRegistration: registrations[0],
        vapidKey: vapidKey(),
      }).then((token) => {
        window.MobileDevice.register(window.navigator.userAgent, 'PWA', 'PWA', token)
      })
    })
  }

  checkServiceWorkerRegistration() {
    const apps = getApps()

    navigator.serviceWorker.getRegistrations().then((registrations) => {
      if (!registrations[0]?.active || apps.length === 0) {
        return setTimeout(() => {
          this.checkServiceWorkerRegistration()
        }, 1000)
      }

      this.displayModal()
    })
  }

  connect() {
    if (!isPWA() || !('Notification' in window) || !('serviceWorker' in navigator)) {
      return
    }

    this.checkServiceWorkerRegistration()
  }

  deny() {
    this.element.classList.add('hidden')
    removeScrollLock()
    Cookies.set(COOKIE_NAME, true, { expires: 7 })
  }

  accept() {
    this.element.classList.add('hidden')
    removeScrollLock()
  }
}
